import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { acPageSideMenuPageAction, acPageSideMenuStateAction } from '@actions/acPage';
import AppBarLogo from '@components/layout/header/AppBar/Logo';
import UserMenu from '@components/layout/UserMenu';
import { dreamCafe, miniSite } from '@constants/config';
import { useTranslation } from '@hooks/useTranslation';
import AccountIcon from '@icons/Account';
import { TThunkDispatch } from '@interfaces/index';
import { ESideMenuPage } from '@interfaces/page/store';
import { IStore } from '@interfaces/store';
import { getAccountColor } from '@utils/siteSettings';
import AppBarMenu from './AppBarMenu/AppBarMenu';
import Search from './Search/Search';

import BagIconSvg from '../../../../public/static/header/app-bar/bag.svg';
import DropdownArrowIconSvg from '../../../../public/static/header/app-bar/dropdown-arrow.svg';
import WishlistIconSvg from '../../../../public/static/header/app-bar/wishlist.svg';

import HeaderStyles from './../Header.styles';
import AppBarStyles from './styles';


interface AppBarDesktopProps {
  onAccountClick: () => void;
  goToHome: () => void;
  goToWishList: () => void;
}

const AppBarDesktop: React.FunctionComponent<AppBarDesktopProps> = ({ onAccountClick, goToHome, goToWishList }) => {
  let timeout: any | null;
  const { t } = useTranslation();
  const classes = AppBarStyles();
  const classesHeader = HeaderStyles({});

  const dispatch: TThunkDispatch = useDispatch<TThunkDispatch>();
  const data = useSelector((state: IStore) => state.pageData.data);
  const onSideMenuHandle = useCallback((state) => dispatch(acPageSideMenuStateAction(state)), [dispatch]);
  const onSideMenuPageHandle = useCallback((page) => dispatch(acPageSideMenuPageAction(page)), [dispatch]);

  const isAuthorized = useSelector((state: IStore) => state.auth.isAuthorized);
  const user = useSelector((state: IStore) => state.auth.user);
  const count = useSelector((state: IStore) => state.cart.count);

  const [userMenuState, changeUserMenuState] = useState(false);

  const onMouseEnterUserMenu = () => {
    if (isAuthorized) {
      if (!userMenuState) {
        changeUserMenuState(true);
      }
      if (timeout) {
        clearTimeout(timeout);
      }
    }
  };

  const onMouseLeaveUserMenu = () => {
    if (userMenuState) {
      timeout = setTimeout(() => {
        changeUserMenuState(false);
      }, 300);
    }
  };

  useEffect(() => {
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);


  const isCartNotEmpty = count > 0;
  return (
    <>
      <div id="appBar" className={classNames(classes.appBar, classesHeader.headerContainer)}>
        <div className={classes.logoContainer} onClick={goToHome}>
          <AppBarLogo />
        </div>
        {!miniSite && (
          <div className={classes.searchMenuContainer}>
            <AppBarMenu />
            <Search />
          </div>
        )}


        <div className={classes.iconsContainer}>
          {!dreamCafe && (
            <span
              id="open-login-modal"
              className={classNames(classes.icon, classes.iconAccount, { [classes.authorized]: isAuthorized })}
              onClick={onAccountClick}
              onMouseEnter={(e) => onMouseEnterUserMenu()}
              onMouseLeave={(e) => onMouseLeaveUserMenu()}
            >
            <AccountIcon />
            <span className={classNames(classes.label, classes.labelIcon)}>
              {isAuthorized ? user?.data?.attributes['first-name'] : t('common.account')}
              <DropdownArrowIconSvg />
            </span>
              {userMenuState && isAuthorized && (
                <UserMenu state={userMenuState} backgroundColor={getAccountColor(data)} />
              )}
          </span>
          )}
          {!dreamCafe && (
            <span id="open-wishlist" className={classNames(classes.icon, classes.iconAction)} onClick={goToWishList}>
            <WishlistIconSvg />
            <span className={classes.label}>{t('common.wishlist')}</span>
            </span>
          )}
          {!dreamCafe && (
            <span
              id="open-cart-modal"
              className={classNames(classes.icon, classes.iconAction)}
              onClick={() => {
                onSideMenuPageHandle(ESideMenuPage.cart);
                onSideMenuHandle(true);
              }}
            >
            <BagIconSvg />
            <span className={classes.label}>{t('common.bag')}</span>
              {isCartNotEmpty ? <span className={classes.countBadge}>{count}</span> : ''}
          </span>
          )}
        </div>
      </div>

    </>
  )
    ;
};

export default AppBarDesktop;
